import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import './src/assets/styles/index.css';

import './src/styles/build.css';
import React from 'react';
import { BannerProvider } from './src/context/BannerContext';

export const wrapRootElement = ({ element }) => {
    return <BannerProvider>{element}</BannerProvider>;
};
