import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { globalHistory } from '@reach/router';

interface BannerContextProps {
    text: string;
    type?: 'success' | 'error';
}

interface BannerContextValue {
    showBanner: (props: BannerContextProps) => void;
    hideBanner: () => void;
}

const BannerContext = createContext<BannerContextValue>({
    showBanner: () => {},
    hideBanner: () => {},
});

export const BannerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [text, setText] = useState('');
    const [type, setType] = useState<BannerContextProps['type']>('success');
    const ref = useRef<HTMLButtonElement>(null);

    function showBanner({ text, type }: BannerContextProps) {
        setText(text);
        setType(type || 'success');
    }

    function hideBanner() {
        setText('');
    }

    useEffect(() => {
        ref.current?.focus();
    }, [text]);

    useEffect(() => {
        const unsubscribe = globalHistory.listen(({ action }) => {
            if (action === 'PUSH') {
                hideBanner();
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <BannerContext.Provider value={{ showBanner, hideBanner }}>
            <div role="alert" aria-label={type}>
                {text !== '' && (
                    <div
                        className={`fixed top-0 left-0 w-full p-3 text-center text-white z-10
                                    ${type === 'success' ? 'bg-primary-500' : 'bg-red-700'}
                                    `}
                    >
                        {text}
                        <button
                            aria-label="Close Banner"
                            className="absolute w-6 h-6 bg-red-900 rounded-full right-3 line-height-[24px] text-center"
                            onClick={hideBanner}
                            ref={ref}
                        >
                            X
                        </button>
                    </div>
                )}
            </div>
            {children}
        </BannerContext.Provider>
    );
};

export const useBanner = () => useContext(BannerContext);
